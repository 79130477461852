import axios from "axios";
import { useEffect, useState } from "react";

const useMission = () => {
  const [user, setUser] = useState<any>();
  const [missionsData, setMissionsData] = useState<any>();

  const handleUser = async () => {
    try {
      const userStorage = JSON.parse(localStorage.getItem("user") ?? "");

      const userData = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userStorage.id}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );

      setUser(userData.data);
    } catch (error: any) {
      // console.log(error);
    }
  };

  const handleMissionsData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("user") ?? "");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/user/missions",
        {
          headers: {
            Authorization: "Bearer " + token.token,
          },
        }
      );
      setMissionsData(response.data);
    } catch (error: any) {
      // console.log(error);
    }
  };

  useEffect(() => {
    handleUser();
    handleMissionsData();
  }, []);

  return [user, missionsData];
};

export default useMission;
