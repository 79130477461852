import React, { useState, useRef } from "react";
import Nav from "../../components/Nav/Nav";
import { toast } from "react-hot-toast";
import useConfiguration from "./hooks/useConfiguration";
import "./configuration.css";
import axios from "axios";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const Configuration: React.FC = () => {
  const navigate = useNavigate();
  const [getUser, user] = useConfiguration();
  const [photo, setPhoto] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [clearData, setClearData] = useState<string>("clear-balance");
  const [avatarChanged, setAvatarChanged] = useState<File | null>(null);
  const inputPassword = useRef<HTMLInputElement>(null);
  const inputPassword2 = useRef<HTMLInputElement>(null);

  if (!photo && user && user.photo.includes("."))
    setPhoto(`${process.env.REACT_APP_API_URL}/upload/${user?.photo}`);
  else if (!photo && user) setPhoto(`user.png`);

  const handleAvatar = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    if (!ev.target.files) return;

    const file = ev.target.files[0];
    const url = URL.createObjectURL(file);
    setPhoto(url);
    setAvatarChanged(ev.target.files[0]);
  };

  const handleSave = async () => {
    if (avatarChanged) {
      try {
        const user = JSON.parse(localStorage.getItem("user") ?? "");
        var formData = new FormData();
        formData.append("avatar", avatarChanged);
        await axios.post(process.env.REACT_APP_API_URL + "/user/profile-picture", formData, {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "multipart/form-data",
          },
        });
        await getUser();
        toast.success("Imagem alterada.", {
          position: "top-center",
          duration: 5000,
        });
      } catch (error: any) {
        console.log(error);
        toast.error(error.response?.data?.message ?? "Erro ao fazer upload.", {
          position: "top-center",
          duration: 5000,
        });
      }
    }
  };

  const handleConfirmClearData = async () => {
    if (!password) return;

    try {
      const user = JSON.parse(localStorage.getItem("user") ?? "");
      await axios.delete(process.env.REACT_APP_API_URL + "/user-clear-data", {
        data: {
          password: password,
          action: clearData,
        },
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      await getUser();
      toast.success("Dados apagados com sucesso.", {
        position: "top-center",
        duration: 5000,
      });
      setPassword("");
    } catch (error: any) {
      console.log(error);
      toast.error(error.response?.data?.message ?? "Erro ao apagar os dados.", {
        position: "top-center",
        duration: 5000,
      });
    }
  };

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClearData(event.target.value);
  };

  const removeAccount = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user") ?? "");
      await axios.delete(process.env.REACT_APP_API_URL + "/user-remove", {
        data: {
          password: password,
        },
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      setPassword("");
      localStorage.removeItem("user");
      navigate("/login");
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        setPassword("");
        toast.error(error.response?.data?.message ?? "Erro ao apagar a conta.", {
          position: "top-center",
          duration: 5000,
        });
      }
    }
  };

  return (
    <>
      <Nav user={user} hideBalance={false} />
      <div className="container-entrys">
        <div className="config-page">
          <h2 className="config-title text-center">Configurações</h2>
          <div>
            <h5>Foto de perfil</h5>
            <i>Escolha uma foto nova ou altere a atual</i>
            <img className="perfil-photo config-photo" src={photo} alt="efi" />
            <input className="form-control config-avatar" type="file" id="formFile" onChange={handleAvatar} />
            <hr />
          </div>
          <div>
            <h5>Limpar dados</h5>
            <i>Limpeza de saldo, movimentações ou ambos</i>
            <div className="cleanData">
              <div className="check-div">
                <input
                  type="radio"
                  id="clear-balance"
                  name="clear-data"
                  onChange={radioHandler}
                  value="clear-balance"
                  checked={clearData == "clear-balance" ? true : false}
                />
                <label htmlFor="clear-balance" className="clear-data-text">
                  Saldo
                </label>
              </div>
              <div className="check-div">
                <input
                  type="radio"
                  id="clear-movimentation"
                  name="clear-data"
                  onChange={radioHandler}
                  value="clear-movimentation"
                  checked={clearData == "clear-movimentation" ? true : false}
                  disabled
                />
                <label htmlFor="clear-movimentation" className="clear-data-text disabled">
                  Movimentações
                </label>
              </div>
              <div className="check-div">
                <input
                  type="radio"
                  id="clear-all"
                  name="clear-data"
                  onChange={radioHandler}
                  value="clear-all"
                  checked={clearData == "clear-all" ? true : false}
                  disabled
                />
                <label htmlFor="clear-all" className="clear-data-text disabled">
                  Tudo
                </label>
              </div>
              {/* <button className="btn btn-danger">Limpar movimentações</button>
              <button className="btn btn-danger">Limpar saldo e movimentações</button> */}
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Button
                name="Limpar dados"
                color="white"
                backgroundColor="crimson"
                dataBsToggle="modal"
                dataBsTarget="#exampleModal"
              />
            </div>
            <hr />
          </div>
          <div>
            <h5>Conta</h5>
            <i>Informações da sua conta</i>
            <div>
              <span className="infoAccount">Login</span> {user?.login}
            </div>
            <div>
              <span className="infoAccount">E-mail</span> {user?.email}
              <button>
                <i
                  className="fa-solid fa-pen-to-square"
                  data-bs-toggle="tooltip"
                  title="Editar e-mail"
                  data-bs-placement="bottom"
                ></i>
              </button>
            </div>
            <div>
              <span className="infoAccount">Seu saldo</span> R$ {user?.balance.toFixed(2).replace(".", ",")}
            </div>
            <div>
              <span className="infoAccount">Suas moedas lua</span> {user?.moonCoin ?? 0}
            </div>
            <div>
              <span className="infoAccount">
                Você está no <span className="logo-font-nav-mini">finance</span> desde
              </span>{" "}
              {new Date(user?.createdAt).toLocaleDateString()}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                name="EXCLUIR CONTA"
                backgroundColor="crimson"
                color="white"
                dataBsToggle="modal"
                dataBsTarget="#exampleModalErasedata"
              />
            </div>
            <hr />
          </div>
          <div className="d-flex justify-content-end">
            <Button name="SALVAR" click={handleSave} />
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirma a remoção de dados?
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body input-value">
                <div>
                  <label>Senha</label>
                </div>
                <input
                  type="password"
                  className="input-value-children"
                  id="clear-password"
                  ref={inputPassword}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                <Button
                  class="cancel-modal"
                  name="CANCELAR"
                  dataBsDismiss="modal"
                  backgroundColor="#c20114"
                  color="white"
                />
                <Button
                  class="confirm-modal"
                  name="CONFIRMAR"
                  dataBsDismiss="modal"
                  click={handleConfirmClearData}
                  backgroundColor="#054a29"
                  color="white"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalErasedata"
          tabIndex={-1}
          aria-labelledby="exampleModalErasedataLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalErasedataLabel">
                  Confirma a remoção da conta?
                </h5>
                <div></div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body input-value">
                <div>
                  <label>Senha</label>
                </div>
                <input
                  type="password"
                  className="input-value-children"
                  id="clear-password2"
                  ref={inputPassword2}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                <i style={{ fontSize: "14px" }}>
                  Aviso: tenha certeza antes de remover sua conta, esta ação é irreversível.
                </i>
                <Button
                  class="cancel-modal"
                  name="CANCELAR"
                  dataBsDismiss="modal"
                  backgroundColor="#c20114"
                  color="white"
                />
                <Button
                  class="confirm-modal"
                  name="CONFIRMAR"
                  dataBsDismiss="modal"
                  backgroundColor="#054a29"
                  color="white"
                  click={removeAccount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuration;
