import React from "react";
import { Card, ProgressBar } from "react-bootstrap";
import useMission from "./hooks/useMission";
import Nav from "../../components/Nav/Nav";
import "./mission.css";

const Mission: React.FC = () => {
  const [user, missionsData] = useMission();

  return (
    <>
      <Nav user={user} hideBalance={true} />
      <div className="container-entrys">
        <h2 className="config-title text-center">Conquistas</h2>
        <div className="cards-missions">
          {!missionsData ? (
            <div>Carregando. . .</div>
          ) : missionsData.length == 0 ? (
            <div>Sem missões.</div>
          ) : (
            missionsData.map((item: any, index: any) => {
              return (
                <Card key={index} className="card-mission">
                  <Card.Title className="title-mission">
                    {item.mission.name}
                    <Card.Img
                      className="icon-mission"
                      src={`${process.env.REACT_APP_API_URL}/upload/${item.mission.icon}`}
                    />
                  </Card.Title>
                  <Card.Body>
                    <Card.Text>Descrição: {item.mission.description}</Card.Text>
                    <ProgressBar
                      animated
                      now={(item.progress / item.mission.totalProgress) * 100}
                      label={item.progress + "/" + item.mission.totalProgress}
                    />
                    <Card.Text>Status: {item.status}</Card.Text>
                    Recompensa:
                    <Card.Text className="coin-mission">
                      <img
                        className="moon-coin-photo"
                        src="lua_moeda_login.png"
                        alt="efi"
                      ></img>
                      {item.mission.prize}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Mission;
