import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useNav = () => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState<any>();

  const handleNotificationData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("user") ?? "");
      if (!token) return navigate("/login");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/user-notifications",
        {
          headers: {
            Authorization: "Bearer " + token.token,
          },
        }
      );
      setNotificationData(response.data);
    } catch (error: any) {
      if (error.response?.status == 401) {
        return navigate("/login");
      }
    }
  };

  useEffect(() => {
    handleNotificationData();
  }, []);

  return [notificationData];
};

export default useNav;
