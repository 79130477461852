import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "./newPassword.css";
import Button from "../../components/Button/Button";

const NewPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  let { token } = useParams();

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/user/resetPassword", {
        token,
        password,
      });
      toast.success("Senha atualizada.", {
        position: "top-center",
        duration: 5000,
      });
      navigate("/");
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? "Falhou. . .", {
        position: "top-center",
        duration: 5000,
      });
    }
  };
  return (
    <div className="containerFather container-newpassword">
      <div className="containerMy">
        <div className="container__svgs">
          <div className="container__svg1">
            <img className="container__svg1" src="lua_moeda_login.png" />
          </div>
          <div className="container__svg2">
            <img className="container__svg2" src="sombra_login.png" />
          </div>
        </div>
        <span className="container__separatorMy"></span>
        <div className="container__content">
          {/* <img src="/Finance_Logo_Branco.png" alt="efi" width={200} /> */}
          <h3 className="logo-font-nav">finance</h3>
          <h3 className="text-new-user">Nova senha</h3>
          <form className="container__form">
            <div className="eyers-newpass">
              <input
                className="container__inputField"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Senha"
                value={password}
                maxLength={14}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="eye-newpass">
                <IconButton onClick={handleClickShowPassword} className="eye-registration-icon">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </span>
            </div>
            <Button name="ENVIAR" click={handleSubmit} />
          </form>
          <div className="container__links">
            <a className="container__link link-register" href="/login">
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
