import React from "react";
import useDashboard from "./hooks/useDashboard";
import Nav from "../../components/Nav/Nav";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveTimeRange } from "@nivo/calendar";
import "./dashboard.css";

const Dashboard: React.FC = () => {
  const [user, data] = useDashboard();
  const translateDate = (year: number, month: number, date: Date) => {
    const monthName = date.toLocaleString("default", { month: "long" });
    const sun = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(1)"
    );
    if (sun) sun.innerHTML = "Domingo";
    const mon = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(2)"
    );
    if (mon) mon.innerHTML = "Segunda";
    const tue = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(3)"
    );
    if (tue) tue.innerHTML = "Terça";
    const wed = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(4)"
    );
    if (wed) wed.innerHTML = "Quarta";
    const thir = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(5)"
    );
    if (thir) thir.innerHTML = "Quinta";
    const fri = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(6)"
    );
    if (fri) fri.innerHTML = "Sexta";
    const sat = document.querySelector(
      "#main > div.container-entrys > div.bar-chart-git > div > div > svg > g > text:nth-child(7)"
    );
    if (sat) sat.innerHTML = "Sábado";
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };
  // let data = [
  //   {
  //     value: "Pett",
  //     id: 300,
  //     idColor: "#fbf8cc",
  //   },
  //   {
  //     value: "Testt",
  //     id: 450,
  //     idColor: "#fde4cf",
  //   },
  //   {
  //     value: "Pet",
  //     id: 530,
  //     idColor: "#ffcfd2",
  //   },
  //   {
  //     value: "Viagem",
  //     id: 405,
  //     idColor: "#f1c0e8",
  //   },
  //   {
  //     value: "Eletrônico",
  //     id: 700,
  //     idColor: "#cfbaf0",
  //   },
  //   {
  //     value: "Casa",
  //     id: 563,
  //     idColor: "#a3c4f3",
  //   },
  //   {
  //     value: "Transporte",
  //     id: 289,
  //     idColor: "#90dbf4",
  //   },
  //   {
  //     value: "Contas",
  //     id: 307,
  //     idColor: "#8eecf5",
  //   },
  //   {
  //     value: "Roupas",
  //     id: 240,
  //     idColor: "#98f5e1",
  //   },
  //   {
  //     value: "Lazer",
  //     id: 240,
  //     idColor: "#b9fbc0",
  //   },
  // ];
  let datatime = [
    {
      value: 1,
      day: "2023-05-01",
    },
    {
      value: 113,
      day: "2023-06-04",
    },
    {
      value: 260,
      day: "2023-05-22",
    },
    {
      value: 221,
      day: "2023-04-27",
    },
    {
      value: 384,
      day: "2023-06-11",
    },
    {
      value: 294,
      day: "2023-05-07",
    },
    {
      value: 399,
      day: "2023-08-03",
    },
    {
      value: 17,
      day: "2023-07-11",
    },
    {
      value: 153,
      day: "2023-08-10",
    },
    {
      value: 108,
      day: "2023-04-15",
    },
    {
      value: 259,
      day: "2023-05-03",
    },
    {
      value: 353,
      day: "2023-06-03",
    },
    {
      value: 129,
      day: "2023-05-14",
    },
    {
      value: 74,
      day: "2023-07-25",
    },
    {
      value: 135,
      day: "2023-06-23",
    },
    {
      value: 224,
      day: "2023-07-12",
    },
    {
      value: 77,
      day: "2023-07-28",
    },
    {
      value: 64,
      day: "2023-05-09",
    },
    {
      value: 197,
      day: "2023-06-09",
    },
    {
      value: 185,
      day: "2023-06-14",
    },
    {
      value: 48,
      day: "2023-05-11",
    },
    {
      value: 43,
      day: "2023-08-07",
    },
    {
      value: 38,
      day: "2023-07-03",
    },
    {
      value: 133,
      day: "2023-05-25",
    },
    {
      value: 196,
      day: "2023-04-26",
    },
    {
      value: 181,
      day: "2023-04-06",
    },
    {
      value: 151,
      day: "2023-08-08",
    },
    {
      value: 377,
      day: "2023-04-09",
    },
    {
      value: 284,
      day: "2023-06-12",
    },
    {
      value: 96,
      day: "2023-04-17",
    },
    {
      value: 322,
      day: "2023-04-23",
    },
    {
      value: 361,
      day: "2023-06-25",
    },
    {
      value: 286,
      day: "2023-04-10",
    },
    {
      value: 256,
      day: "2023-04-04",
    },
    {
      value: 319,
      day: "2023-04-16",
    },
    {
      value: 68,
      day: "2023-06-07",
    },
    {
      value: 126,
      day: "2023-08-06",
    },
    {
      value: 328,
      day: "2023-08-01",
    },
    {
      value: 76,
      day: "2023-04-28",
    },
    {
      value: 41,
      day: "2023-04-21",
    },
    {
      value: 253,
      day: "2023-06-27",
    },
    {
      value: 167,
      day: "2023-05-15",
    },
    {
      value: 270,
      day: "2023-08-11",
    },
    {
      value: 33,
      day: "2023-04-19",
    },
    {
      value: 383,
      day: "2023-05-02",
    },
    {
      value: 202,
      day: "2023-06-20",
    },
    {
      value: 339,
      day: "2023-07-30",
    },
    {
      value: 61,
      day: "2023-07-23",
    },
    {
      value: 274,
      day: "2023-04-11",
    },
    {
      value: 115,
      day: "2023-06-17",
    },
    {
      value: 186,
      day: "2023-07-14",
    },
    {
      value: 363,
      day: "2023-05-29",
    },
    {
      value: 336,
      day: "2023-05-21",
    },
    {
      value: 260,
      day: "2023-04-22",
    },
    {
      value: 88,
      day: "2023-07-24",
    },
    {
      value: 312,
      day: "2023-05-27",
    },
    {
      value: 245,
      day: "2023-04-14",
    },
    {
      value: 308,
      day: "2023-06-05",
    },
    {
      value: 277,
      day: "2023-08-04",
    },
    {
      value: 316,
      day: "2023-07-02",
    },
    {
      value: 224,
      day: "2023-04-05",
    },
    {
      value: 85,
      day: "2023-07-22",
    },
    {
      value: 248,
      day: "2023-06-21",
    },
    {
      value: 285,
      day: "2023-06-29",
    },
    {
      value: 229,
      day: "2023-06-10",
    },
    {
      value: 86,
      day: "2023-04-20",
    },
    {
      value: 382,
      day: "2023-05-06",
    },
    {
      value: 253,
      day: "2023-05-13",
    },
    {
      value: 174,
      day: "2023-08-02",
    },
    {
      value: 267,
      day: "2023-05-18",
    },
    {
      value: 105,
      day: "2023-04-13",
    },
    {
      value: 108,
      day: "2023-04-25",
    },
    {
      value: 276,
      day: "2023-07-17",
    },
    {
      value: 94,
      day: "2023-07-31",
    },
    {
      value: 194,
      day: "2023-07-15",
    },
    {
      value: 137,
      day: "2023-01-16",
    },
    {
      value: 158,
      day: "2023-06-13",
    },
    {
      value: 198,
      day: "2023-02-08",
    },
    {
      value: 47,
      day: "2023-04-01",
    },
    {
      value: 231,
      day: "2023-06-22",
    },
    {
      value: 66,
      day: "2023-07-19",
    },
    {
      value: 104,
      day: "2023-09-31",
    },
    {
      value: 32,
      day: "2023-10-09",
    },
    {
      value: 322,
      day: "2023-11-30",
    },
  ];
  return (
    <>
      <Nav user={user} hideBalance={true} />
      <div className="container-entrys container-entrys-dash">
        <h2 className="config-title text-center">Painel</h2>
        <h5 className="title-categorychart hide-responsive-text">
          Movimentações por categoria
        </h5>
        <div className="container-bar-chart">
          <div className="bar-chart">
            {!data ? (
              <div>Carregando. . .</div>
            ) : (
              <ResponsiveBar
                data={data}
                keys={["id"]}
                indexBy="value"
                margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
                padding={0.3}
                colors={({ data }) => data["idColor"]?.toString()}
                // fill={[
                //   {
                //     match: "*",
                //     id: "dots",
                //   },
                // ]}
                // defs={[
                //   {
                //     id: "dots",
                //     type: "patternLines",
                //     background: "whitesmoke",
                //     color: "inherit",
                //     rotation: -45,
                //     lineWidth: 6,
                //     spacing: 10,
                //   },
                // ]}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "categorias",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickValues: 6,
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "quantidade em reais",
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
                theme={{
                  axis: {
                    ticks: { text: { fill: "var(--color)" } },
                    legend: { text: { fill: "var(--color)" } },
                  },
                }}
                tooltip={({ data }) => {
                  return (
                    <div
                      className="toltip-bar-chart"
                      style={{
                        padding: "7px 10px",
                        border: "1px solid #ccc",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          backgroundColor: `${data.idColor}`,
                          marginRight: 4,
                        }}
                      ></div>
                      {data.value} R$ {data.id}
                    </div>
                  );
                }}
              />
            )}
          </div>
          <div className="bar-chart-details">
            <h5 className="title-categorychart-details">Top movimentações</h5>
            {!data ? (
              <div>Carregando. . .</div>
            ) : (
              data.slice(0, 6).map((item: any, index: any) => {
                return (
                  <div className="label-details" key={index}>
                    <div>{item.value}</div>
                    <div>R$ {item.id}</div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
