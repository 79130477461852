import React from "react";
import "./notFound.css";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate("/");
  };

  return (
    <>
      <div className="not-found">
        <i className="fa-solid fa-triangle-exclamation warning"></i>
        Página não encontrada. (404)
      </div>
      <div className="not-found-button">
        <Button name="VOLTAR" click={handleClickBack} />
      </div>
    </>
  );
};

export default NotFound;
