import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Button from "../../components/Button/Button";
// import "./forgotPassword.css";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsRequesting(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/user/forgotpassword", {
        email,
      });
      toast.success("E-mail enviado.", {
        position: "top-center",
        duration: 5000,
      });
      setIsRequesting(false);
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? "Falhou. . .", {
        position: "top-center",
        duration: 5000,
      });
      setIsRequesting(false);
    }
  };
  return (
    <div className="containerFather">
      <div className="containerMy">
        <div className="container__svgs">
          <div className="container__svg1">
            <img className="container__svg1" src="lua_moeda_login.png" />
          </div>
          <div className="container__svg2">
            <img className="container__svg2" src="sombra_login.png" />
          </div>
        </div>
        <span className="container__separatorMy"></span>
        <div className="container__content">
          <h3 className="text-new-user">Recuperar senha</h3>
          <form className="container__form">
            <input
              className="container__inputField"
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button name="ENVIAR" click={handleSubmit} disabled={isRequesting} />
          </form>
          <div className="container__links">
            <a className="container__link link-register" href="/login">
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
