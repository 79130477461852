import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./nav.css";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "../Button/Button";
import useNav from "./hooks/useNav";
import axios from "axios";
import { io } from "socket.io-client";
import { Tooltip } from "bootstrap";
import toast from "react-hot-toast";

export let webSocket = io();

type Props = {
  user: any;
  hideBalance?: boolean;
};

const Nav: React.FC<Props> = ({ ...props }: Props) => {
  const [showValue, setShowValue] = useState(false);
  const [name, setName] = useState<string>();
  const [photo, setPhoto] = useState<string>();
  const [logo, setLogo] = useState<string>();
  const [notification, setNotification] = useState<any[]>([]);
  const [themes, setThemes] = useState<any[]>([
    { theme: "light", icon: "fa-regular fa-sun" },
    { theme: "dark", icon: "fa-solid fa-moon" },
  ]);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  let [notificationData] = useNav();
  const navigate = useNavigate();

  useEffect(() => {
    enableTooltip();
    connectWebSocket();
  }, []);

  const enableTooltip = () => {
    var tooltipTriggleList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggleList.map((tooltipTriggleEl) => {
      return new Tooltip(tooltipTriggleEl);
    });
  };

  const logout = async () => {
    localStorage.removeItem("user");
    webSocket.disconnect();
    navigate("/login");
  };

  if (notificationData && notificationData.length > 0 && notification.length == 0 && !firstLoading) {
    setNotification(notificationData);
    setFirstLoading(true);
  }

  const connectWebSocket = () => {
    // webSocket = io("https://apifinance.murphis.dev");
    webSocket = io(`${process.env.REACT_APP_API_URL}`);

    if (!localStorage.getItem("user")) {
      return;
    }
    const user = JSON.parse(localStorage.getItem("user") ?? "");
    applyTheme(user.theme);

    if (!user || !user.id) return;
    webSocket.emit("auth", {
      user: user.id,
      token: user.token,
    });

    let notifications: any = [];

    webSocket.on("message", function (event) {
      if (event && event.title && event.title == "notification") {
        setNotification((prev) => [event, ...prev]);
      }
    });

    webSocket.on("disconnect", function (event) {
      setTimeout(() => {
        if (!webSocket.connected) connectWebSocket();
      }, 5000);
    });
  };

  const handleClickShowValue = () => {
    setShowValue((s) => !s);
  };

  const formatValue = (value: number) => {
    const valueFormatted = value.toFixed(2).replace(".", ",");

    return valueFormatted;
  };

  const handleReadNotification = async () => {
    if (
      notification &&
      notification.length > 0 &&
      notification.filter((item) => item.read == false).length > 0
    ) {
      try {
        const user = JSON.parse(localStorage.getItem("user") ?? "");
        await axios.put(
          process.env.REACT_APP_API_URL + "/user-notifications",
          {
            read: true,
          },
          {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          }
        );
        const updatedArray = notification.map((item) => ({
          ...item,
          read: true,
        }));
        setNotification(updatedArray);
      } catch (error: any) {}
    }
  };

  const handleNavDropdown = () => {
    if (document.querySelector("#ul-dropdown-nav")?.classList.toString().includes("show")) {
      document.querySelector("#ul-dropdown-nav")?.classList.remove("show");
    } else {
      document.querySelector("#ul-dropdown-nav")?.classList.add("show");
      handleReadNotification();
    }
  };

  const handleNavDropdownThemes = () => {
    if (document.querySelector("#ul-dropdown-nav-theme")?.classList.toString().includes("show")) {
      document.querySelector("#ul-dropdown-nav-theme")?.classList.remove("show");
    } else {
      document.querySelector("#ul-dropdown-nav-theme")?.classList.add("show");
      handleReadNotification();
    }
  };

  const linksMap = (link: string) => {
    if (link == "MISSION") return "/Missions";

    return "/";
  };

  const showNavbar = (toggleId: string, navId: string, bodyId: string) => {
    const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId),
      bodypd = document.getElementById(bodyId),
      perfilname = document.getElementsByClassName("perfil-name")[0];

    if (toggle && nav && bodypd) {
      nav.classList.toggle("show");
      toggle.classList.toggle("bx-x");
      bodypd.classList.toggle("body-pd");
      perfilname.classList.toggle("invisible-name");
    }
    if (!props.user) return;
    if (props.user.photo && props.user.photo.includes(".")) {
      setPhoto(`${process.env.REACT_APP_API_URL}/upload/${props.user.photo}`);
    } else {
      setPhoto(`user.png`);
    }
    setName(props.user.login);
  };

  const handleChangeTheme = async (ev: React.MouseEvent) => {
    try {
      const theme = ev.currentTarget?.getAttribute("data-theme")?.toUpperCase() ?? "";
      const user = JSON.parse(localStorage.getItem("user") ?? "");
      if (theme == user.theme) return;

      await axios.put(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          theme,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      user.theme = theme;
      localStorage.setItem("user", JSON.stringify(user));
      applyTheme(theme);

      toast.success("Tema atualizado.", {
        position: "top-center",
        duration: 5000,
      });
    } catch (error: any) {
      toast.error("Erro inesperado.", {
        position: "top-center",
        duration: 5000,
      });
    }
  };

  const applyTheme = (theme: string) => {
    theme = theme?.toLowerCase();

    if (theme == "dark") {
      setLogo("Finance_Logo_Branco.png");
      document.body.style.setProperty("--backgroundColor", "var(--backgroundColor9)");
      document.body.style.setProperty("--backgroundColor3", "var(--backgroundColor11)");
      document.body.style.setProperty("--backgroundColor2", "var(--backgroundColor10)");
      document.body.style.setProperty("--color", "var(--color5)");
    } else if (theme == "light") {
      setLogo("Finance_Logo_Preto.png");
      document.body.style.setProperty("--backgroundColor", "var(--backgroundColor7)");
      document.body.style.setProperty("--backgroundColor3", "var(--backgroundColor6)");
      document.body.style.setProperty("--backgroundColor2", "var(--backgroundColor8)");
      document.body.style.setProperty("--color", "var(--color4)");
    }
  };

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container-fluid mx-2">
          <div className="navbar-header">
            <i
              className="fa fa-bars me-5"
              id="header-toggle"
              // data-bs-toggle="tooltip"
              // title="Menu"
              data-bs-placement="bottom"
              onClick={() => showNavbar("header-toggle", "nav-bar", "body-pd")}
            ></i>
            {/* <img src={logo} alt="efi" width={200} /> */}
            <h3 className="logo-font-nav">finance</h3>
            <div className="hide-responsive-device">
              Bem vindo(a), {props.user?.login?.toUpperCase() ?? ""}
            </div>
          </div>
          <div>
            <ul className="navbar-nav ms-auto">
              <li className="balance-container" hidden={props.hideBalance}>
                <span className="balance-value">
                  <div>
                    Saldo{" "}
                    <span className="eye-value">
                      <IconButton onClick={handleClickShowValue} className="eye-value-icon">
                        {showValue ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </span>
                  </div>
                  {showValue ? formatValue(props.user?.balance) ?? "" : "____________"}
                </span>
              </li>
              <li className="nav-item dropdown nav-notify hide-responsive">
                {notification &&
                notification.length > 0 &&
                notification.filter((item) => item.read == false).length > 0 ? (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {notification.filter((item) => item.read == false).slice(0, 6).length}
                  </span>
                ) : (
                  <></>
                )}
                <i
                  className="nav-link fa-regular fa-envelope"
                  onClick={handleNavDropdown}
                  role="button"
                  data-bs-toggle="tooltip"
                  title="Mensagens"
                  data-bs-placement="bottom"
                ></i>
                <ul className="dropdown-menu" id="ul-dropdown-nav">
                  {notification && notification.length == 0 ? (
                    <li className="not-selectable">
                      <span className="dropdown-item">Nenhuma notificação.</span>
                    </li>
                  ) : (
                    <></>
                  )}
                  {notification &&
                    notification.slice(0, 6).map((e: any, i: any) => {
                      return (
                        <li key={i} className="not-selectable">
                          <a className="dropdown-item" href={linksMap(e.link)}>
                            {e.message}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </li>
              <li className="nav-item dropdown theme-brush hide-responsive-device">
                <i
                  className="nav-link fa-solid fa-brush"
                  onClick={handleNavDropdownThemes}
                  role="button"
                  data-bs-toggle="tooltip"
                  title="Temas"
                  data-bs-placement="bottom"
                ></i>
                <ul className="dropdown-menu" id="ul-dropdown-nav-theme">
                  {themes.map((e: any, i: any) => {
                    return (
                      <li key={i} className="not-selectable" onClick={handleChangeTheme} data-theme={e.theme}>
                        <span className="dropdown-item">
                          <i className={e.icon}></i> {e.theme}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="buy-coffee hide-responsive-device">
                <a href="https://bmc.link/finance.murphis.dev" target="_blank">
                  <i
                    className="fa-solid fa-mug-hot"
                    role="button"
                    data-bs-toggle="tooltip"
                    title="Café"
                    data-bs-placement="bottom"
                  ></i>
                </a>
              </li>
              <li className="nav-item hide-responsive-device">
                <Button name="SAIR" click={logout} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="body-pd">
        <div className="side-navbar" id="nav-bar">
          <nav className="nav">
            <div>
              <div className="perfil">
                <div className="perfil-photo-name">
                  <img className="perfil-photo" src={photo} alt="efi"></img>
                  <span className="perfil-name invisible-name">{name?.toUpperCase()}</span>
                </div>
                <div className="moon-coin">
                  <img className="moon-coin-photo" src="lua_moeda_login.png" alt="efi"></img>
                  <span data-bs-toggle="tooltip" title="Moeda Lua" data-bs-placement="bottom">
                    {props.user?.moonCoin ?? 0}
                  </span>
                </div>
              </div>
              <div className="nav_list">
                <a href="/" className="nav_link">
                  <i
                    className="fa-solid fa-building-columns"
                    data-bs-toggle="tooltip"
                    title="Página inicial"
                    data-bs-placement="bottom"
                  ></i>
                  <span className="nav_name">Movimentações</span>
                </a>
                <a href="/Dashboard" className="nav_link hide-responsive-text">
                  <i
                    className="fa fa-chart-line"
                    data-bs-toggle="tooltip"
                    title="Painel"
                    data-bs-placement="bottom"
                  ></i>
                  <span className="nav_name">Painel</span>
                </a>
                <a href="/Missions" className="nav_link">
                  <i
                    className="fa fa-crown"
                    data-bs-toggle="tooltip"
                    title="Conquistas"
                    data-bs-placement="bottom"
                  ></i>
                  <span className="nav_name">Conquistas</span>
                </a>
                {/* <a href="#" className="nav_link">
                  <i
                    className="fa fa-phone"
                    data-bs-toggle="tooltip"
                    title="Suporte"
                    data-bs-placement="bottom"
                  ></i>
                  <span className="nav_name">Suporte</span>
                </a> */}
                <a href="/Configuration" className="nav_link">
                  <i
                    className="fa fa-cog"
                    data-bs-toggle="tooltip"
                    title="Configuração"
                    data-bs-placement="bottom"
                  ></i>
                  <span className="nav_name">Configuração</span>
                </a>
                <a href="#" className="nav_link" onClick={logout}>
                  <i
                    className="fa-solid fa-right-from-bracket"
                    data-bs-toggle="tooltip"
                    title="Sair"
                    data-bs-placement="bottom"
                  ></i>
                  <span className="nav_name">Sair</span>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Nav;
