import { useEffect, useState } from "react";
import axios from "axios";

const useHome = () => {
  const [data, setData] = useState<any>();
  const [categoryData, setCategoryData] = useState<any>();
  const [user, setUser] = useState<any>();

  const handleData = async () => {
    try {
      const userStorage = JSON.parse(localStorage.getItem("user") ?? "");
      const response = await axios.get(process.env.REACT_APP_API_URL + "/movimentation-history", {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });
      setData(response.data);
      const userData = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userStorage.id}`, {
        headers: {
          Authorization: "Bearer " + userStorage.token,
        },
      });

      setUser(userData.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleMovimentations = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user") ?? "");
      const response = await axios.get(process.env.REACT_APP_API_URL + "/movimentation-history", {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      setData(response.data);
      setUser(user);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleCategoryData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("user") ?? "");
      const response = await axios.get(process.env.REACT_APP_API_URL + "/movimentation-history/all-categories", {
        headers: {
          Authorization: "Bearer " + token.token,
        },
      });
      setCategoryData(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleMovimentations();
    handleCategoryData();
  }, []);

  async function refreshData() {
    await handleData();
  }

  return [data, refreshData, categoryData, user];
};

export default useHome;
