import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import useRequireAuth from "../../hooks/useRequire";
import useHome from "./hooks/useHome";
import Nav from "../../components/Nav/Nav";
import { IconButton } from "@mui/material";
import Button from "../../components/Button/Button";
import "./home.css";
import moment from "moment";

const Home: React.FC = () => {
  useRequireAuth();
  const [type, setType] = useState<string>();
  const [value, setValue] = useState<number | any>();
  const [description, setDescription] = useState<string | any>();
  const [categoryId, setCategoryId] = useState<string>();
  const [movimentationId, setMovimentationId] = useState<number>();
  const [balance, setBalance] = useState<number>();
  const [data, refreshData, categoryData, user] = useHome();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem("user") ?? "");
      await axios.post(
        process.env.REACT_APP_API_URL + "/movimentation-history",
        {
          type: type == "entrada",
          value,
          description,
          categoryId: parseInt(categoryId ?? ""),
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      toast.success("Movimentação adicionada.", {
        position: "top-center",
        duration: 5000,
      });
      await refreshData();
      setValue("");
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? "Erro ao adicionar os dados.", {
        position: "top-center",
        duration: 5000,
      });
    }
  };

  const handleRemoveMovimentation = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem("user") ?? "");
      await axios.delete(`${process.env.REACT_APP_API_URL}/movimentation/${movimentationId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      await axios.put(
        process.env.REACT_APP_API_URL + "/user/balance",
        {
          value: balance,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      toast.success("Movimentação removida.", {
        position: "top-center",
        duration: 5000,
      });
      await refreshData();
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? "Erro ao remover os dados.", {
        position: "top-center",
        duration: 5000,
      });
    }
  };

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const categoryHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryId(event.target.value);
  };

  const formatDatetime = (datetime: any) => {
    let date = new Date(datetime);
    const dateFormatted = date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    return dateFormatted;
  };

  const formatDatetimeTest = (datetime: any) => {
    let date = new Date(datetime);
    let dateFormatted: any = date.toLocaleString("pt-BR", {
      weekday: "short",
      day: "numeric",
      month: "long",
    });
    let timeFormatted: any = date.toLocaleString("pt-BR", {
      hour: "numeric",
      minute: "numeric",
    });
    dateFormatted = dateFormatted.replace("de", "").replace(",", "").replace("às", "");

    return timeFormatted + " " + dateFormatted;
  };

  const typeToString = (type: boolean | undefined | string) => {
    const typeString = type == true ? "Entrada" : "Saída";

    return typeString;
  };

  const formatValue = (value: number) => {
    const valueFormatted = "-" + value.toFixed(2).replace(".", ",");

    return valueFormatted;
  };

  const formatDescription = (description: string) => {
    if (description && description.length > 18) description = description.substring(0, 15) + "...";

    return description;
  };

  const handleAccordion = () => {
    if (document.querySelector("#flush-collapseOne")?.classList.toString().includes("d-none")) {
      document.querySelector("#flush-collapseOne")?.classList.remove("d-none");
    } else {
      document.querySelector("#flush-collapseOne")?.classList.add("d-none");
    }
  };

  return (
    <>
      <Nav user={user} hideBalance={false} />
      <div className="container-entrys">
        <h2 className="config-title text-center">Movimentações</h2>
        <form>
          <div className="input-number">
            <div className="category-list">
              <label className="d-block mb-2">Selecione a categoria:</label>
              {!categoryData ? (
                <select>
                  <option>Selecione</option>
                </select>
              ) : (
                <select onChange={categoryHandler}>
                  <option>Selecione</option>
                  {categoryData.map((item: any, index: any) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <div className="input-value hide-responsive">
              <label className="d-block">Descrição:</label>
              <input
                className="input-value-children"
                type="text"
                placeholder="Descrição"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="input-value">
              <label className="d-block">Adicione o valor:</label>
              <input
                className="input-value-children"
                type="number"
                placeholder="R$ 0,00"
                name="value"
                value={value}
                onChange={(e) => setValue(parseFloat(e.target.value))}
              />
            </div>
            <div>
              <div className="d-block mt-2 check-div home-check">
                <input type="radio" name="type" value="saida" id="rd2" onChange={radioHandler} />
                <label htmlFor="rd2">Saída</label>
              </div>
              <div className="d-block check-div home-check">
                <input type="radio" name="type" value="entrada" id="rd1" onChange={radioHandler} />
                <label htmlFor="rd1">Entrada</label>
              </div>
            </div>
            <div>
              <Button name="ADICIONAR" click={handleSubmit} />
            </div>
          </div>
        </form>
      </div>
      <div className="container-history">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={handleAccordion}
              >
                Histórico de movimentações financeiras:
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse d-none"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                {!data ? (
                  <div>loading. . .</div>
                ) : data.length == 0 ? (
                  <div>Sem movimentações.</div>
                ) : (
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th className="hide-responsive-device" scope="col">
                          <i className="fa-brands fa-shopify"></i> Categoria
                        </th>
                        <th className="hide-responsive" scope="col">
                          <i className="fa-solid fa-circle-info"></i> Descrição
                        </th>
                        <th scope="col">
                          <i className="fa-solid fa-brazilian-real-sign"></i> Valor
                        </th>
                        <th className="hide-responsive" scope="col">
                          <i className="fa-solid fa-money-bill-transfer"></i> Tipo
                        </th>
                        <th scope="col">
                          <i className="fa-regular fa-clock"></i> Data
                        </th>
                        <th className="hide-responsive" scope="col">
                          <i className="fa-solid fa-wrench"></i> Ação
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>
                              {item.type == true ? (
                                <i
                                  className="fa-regular fa-circle-check"
                                  style={{
                                    color: "#22ec30",
                                    fontSize: "26px",
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fa-regular fa-circle-xmark"
                                  style={{ color: "red", fontSize: "26px" }}
                                ></i>
                              )}
                            </td>
                            <td className="hide-responsive-device">{item.category?.name}</td>
                            <td
                              className="hide-responsive"
                              data-bs-toggle="tooltip"
                              title={item.description}
                              data-bs-placement="top"
                            >
                              {formatDescription(item.description)}
                            </td>
                            <td style={item.type == true ? { color: "green" } : { color: "red" }}>
                              {item.type == false ? formatValue(item.value) : formatValue(item.value).replace("-", "")}
                            </td>
                            <td className="hide-responsive">{typeToString(item.type)}</td>
                            <td className="hide-responsive">
                              {item.updatedAt != null
                                ? formatDatetime(item.updatedAt)
                                : null ?? formatDatetime(item.createdAt)}
                            </td>
                            <td className="show-responsive">
                              {item.updatedAt != null
                                ? moment(item.updatedAt).format("DD/MM/YYYY")
                                : null ?? moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td className="hide-responsive">
                              <span className="trash-history">
                                <IconButton
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={() => {
                                    setMovimentationId(item.id);
                                    setBalance(item.type == true ? item.value * -1 : item.value);
                                  }}
                                >
                                  <i className="fa-solid fa-trash-can trash"></i>
                                </IconButton>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirma a remoção da movimentação?
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-footer">
                <Button
                  class="cancel-modal"
                  name="CANCELAR"
                  dataBsDismiss="modal"
                  backgroundColor="#c20114"
                  color="#ffffffcf"
                />
                <Button
                  class="confirm-modal"
                  name="CONFIRMAR"
                  dataBsDismiss="modal"
                  click={handleRemoveMovimentation}
                  backgroundColor="#054a29"
                  color="#ffffffcf"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
