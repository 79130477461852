import axios from "axios";
import { useEffect, useState } from "react";

const useConfiguration = () => {
  const [user, setUser] = useState<any>();

  const handleUser = async () => {
    try {
      const userStorage = JSON.parse(localStorage.getItem("user") ?? "");

      const userData = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userStorage.id}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );

      setUser(userData.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUser();
  }, []);

  async function getUser() {
    await handleUser();
  }

  return [getUser, user];
};

export default useConfiguration;
