import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useRequireAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("user");
    if (!token) navigate("/login");
  }, [navigate]);

  return;
};

export default useRequireAuth;
