import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Button from "../../components/Button/Button";
import "./login.css";

const Login: React.FC = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/signin",
        {
          login,
          password,
        }
      );
      localStorage.setItem("user", JSON.stringify(response.data));
      navigate("/");
      toast.success("Bem vindo. . .", {
        position: "top-center",
        duration: 5000,
      });
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? "Falhou. . .", {
        position: "top-center",
        duration: 5000,
      });
    }
  };
  return (
    <div className="containerFather">
      <div className="containerMy">
        <div className="container__svgs">
          <div className="container__svg1">
            <img
              className="container__svg1"
              src="lua_moeda_login.png"
            />
          </div>
          <div className="container__svg2">
            <img
              className="container__svg2"
              src="sombra_login.png"
            />
          </div>
        </div>
        <span className="container__separatorMy"></span>

        <div className="container__content">
          {/* <img src="Finance_Logo_Branco.png" alt="efi" width={200} /> */}
          <h3 className="logo-font-nav">finance</h3>
          <h3 className="text-new-user">Login</h3>
          <form className="container__form">
            <input
              className="container__inputField"
              type="text"
              name="username"
              placeholder="Login"
              value={login}
              onChange={(e) => setLogin(e.target.value.toLowerCase())}
            />
            <div className="eyers">
              <input
                className="container__inputField"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Senha"
                value={password}
                maxLength={14}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="eye-login">
                <IconButton
                  onClick={handleClickShowPassword}
                  tabIndex={-1}
                  className="eye-login-icon"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </span>
            </div>
            <a className="forgotpasswd-login" href="forgotpassword">
              Esqueci minha senha
            </a>
            <Button name="ENVIAR" click={handleSubmit} />
          </form>
          <div className="container__links">
            <span className="container__link">Não tem uma conta?</span>
            <span className="container__separator"></span>
            <a className="container__link link-register" href="/registration">
              Cadastrar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
