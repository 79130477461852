import axios from "axios";
import { useEffect, useState } from "react";

const useDashboard = () => {
  const [user, setUser] = useState<any>();
  const [data, setData] = useState<any>();

  const handleUser = async () => {
    try {
      const userStorage = JSON.parse(localStorage.getItem("user") ?? "");

      const userData = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userStorage.id}`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );

      setUser(userData.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleData = async () => {
    try {
      const userStorage = JSON.parse(localStorage.getItem("user") ?? "");

      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/movimentations-dashboard`,
        {
          headers: {
            Authorization: "Bearer " + userStorage.token,
          },
        }
      );

      setData(data.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUser();
    handleData();
  }, []);

  return [user, data];
};

export default useDashboard;
