import React from "react";
import "./button.css";

type Props = {
  name: string;
  backgroundColor?: string;
  color?: string;
  dataBsDismiss?: string;
  dataBsToggle?: string;
  dataBsTarget?: string;
  class?: string;
  click?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const Button: React.FC<Props> = ({ ...props }: Props) => {
  return (
    <button
      className={`btn-default ${props.class}`}
      onClick={props.click}
      type="submit"
      style={{
        backgroundColor: `${props.backgroundColor}`,
        color: `${props.color}` ?? "white",
      }}
      data-bs-dismiss={props.dataBsDismiss}
      data-bs-toggle={props.dataBsToggle}
      data-bs-target={props.dataBsTarget}
      disabled={props.disabled}
    >
      {props.name}
    </button>
  );
};

export default Button;
