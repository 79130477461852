import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Toast from "../components/Toast/Toast";
import {
  Registration,
  Login,
  Configuration,
  Mission,
  Home,
  ForgotPassword,
  NewPassword,
  Dashboard,
  NotFound,
} from "../pages";

const Router: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/newpassword/:token" element={<NewPassword />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/missions" element={<Mission />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Toast />
    </>
  );
};

export default Router;
